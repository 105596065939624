@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/font/stylesheet.css';

body {
  margin: 0;
  font-family: 'Novecento sans wide', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1vw;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.secondary-text {
  font-family: 'Novecento sans wide Book';
}

.txt-white {
  color: #ffffff;
}

.txt-primary {
  color: #91cbd4;
}

.og-bg-primary {
  color: #91cbd4;
}

.back {
  height: 100vh;
  overflow-y: hidden;
}

.pass {
  height: 200px;
  border-radius: 20px;
  margin: 30px 60px 0;
}

.blue-button-bg {
  background-color: #95f9e4;
}

.og-bg-black {
  background-color: #1d1f20;
}

.active-bg {
  background-color: #ffba00;
}

.selected-img {
  border-color: #ffba00;
}

.text-yellow {
  color: #ffba00;
}

.og-bg-opacity-black {
  background-color:rgba(29, 31, 32, 0.85);
}

.nonconformistducks-bg {
  background-color: rgba(40, 109, 120, 0.85);
}

.nonconformisthellducks-bg {
  background-color: rgba(75, 8, 11, 0.85);
}

.noncoducks2gen-bg {
  background-color: rgba(52, 17, 108, 0.85);
}

.txt-badge {
  color: #ffc600;
}

.background {
  z-index: -10;
}

.oh-90 {
  height: 90%;
}

.oh-55 {
  height: 55%;
}

.ow-90 {
  width: 90%;
}

.tabs {
  width: 6%;
  left: -6%;
  top: 6%;
}

.primary-background {
  z-index: -2;
}

.secondary-background {
  z-index: -3;
}

@media (max-width: 640px) {
  .primary-background {
    min-height: 100vh;
  }
  
  .tabs {
    width: 15%;
    left: -15%;
  }

  .content {
    height: 120vh; /* Fallback for browsers that do not support Custom Properties */
  }

  .none {
    display: none;
  }

  .social {
    width: 100%;
    justify-content: center;
  }

  .profile-duck {
    width: 90%;
  }
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  display: none;

}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none;
}

::-webkit-scrollbar-corner {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #95f9e4;
  border-radius: 10px;
  display: none;
}

.header {
  height: 10%;
}

.mint-button {
  height: 70px;
  margin-top: 10px;
}

.loader {
  height: 50px;
}

.modal {
  height: 80vh;
  padding: 10px;
}

.subtitle {
  height: 15%;
}

.title {
  height: 15%;
  margin-top: 10%;
  margin-bottom: 10%;
}

@media (max-width: 640px) {
  .pass {
    height: auto;
    width: 60vw;
    margin: 10px 20px 0;
  }
  
  .header {
    height: 13%;
    padding: 5px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .loader {
    height: 20px;
  }
  
  .mint-button {
    height: 30px;
  }

  .title {
    height: 12%;
    margin-top: 20%;
    margin-bottom: 10%;
  }

  .plus {
    padding: 10px;
  }

  .modal {
    width: 80vw;
  }

  .minus {
    height: 10%;
  }

  .header-item {
    height: 25px;
  }
}

